import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from 'react-router';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface CardProps {
    icon?: IconProp,
    image?: string,
    button?: { target: string, title: string },
    title: string,
    text: string,
    onClick: any,
    children: any,
    history?: any
}

const Card = (props: CardProps) => {

    let textBoxSize = "w-full";
    if (((props.icon || props.image) && !props.button) || ((!props.icon || !props.image) && props.button)) textBoxSize = "w-5/6";
    if ((props.icon || props.image) && props.button) textBoxSize = "w-4/6";

    return <div
        className={"bg-white shadow-xl rounded-lg overflow-hidden md:flex mb-5 " + (props.onClick !== undefined ? "hover:cursor-pointer" : "")}
        onClick={props.onClick}
    >
        <div className="flex flex-wrap w-full p-5">
            {(props.icon || props.image) && <div className="w-1/6 flex flex-wrap content-center justify-center pr-5">
                {props.icon && <FontAwesomeIcon icon={props.icon}/>}
                {props.image && <img src={props.image} alt="" className="h-12"/>}
            </div>}
            {(props.title || props.text) &&
            <div className={textBoxSize + " flex flex-wrap content-center"}>
                <div className="whitespace-pre-wrap">
                    <p className="font-bold text-xs text-gray-600">{props.title}</p>
                    <p className="text-gray-700 text-xs font-bold pt-1">{props.text}</p>
                </div>
            </div>}
            {props.children}
            {props.button && <div className="w-1/6 flex flex-wrap content-center justify-center hover:cursor-pointer">
                <p className="text-sm text-gray-700 font-bold"
                   onClick={() => props.history.push(props.button && props.button.target)}>{props.button.title}</p>
            </div>}
        </div>
    </div>;
};

// @ts-ignore
export default withRouter(Card);
