import React from "react";
import {withRouter} from "react-router";
import {useTranslation} from "react-i18next";
import NavigationItem from "./NavigationItem";

const Navigation = (props: any) => {
    const {t} = useTranslation();

    // const [menuOpen, setMenuOpen] = React.useState(false);

    const options = [
        {
            icon: ['fad', 'home'],
            title: 'navigation.home',
            pathname: '/',
            classes: ""
        },
        {
            icon: ['fad', 'ticket-alt'],
            title: 'navigation.events',
            pathname: '/events',
            rotation: 42,
            classes: "ml-8"
        },
        {
            icon: ['fad', 'tshirt'],
            title: 'navigation.shop',
            pathname: '/shop',
            classes: "ml-8"
        },
        {
            icon: ['fad', 'user'],
            title: 'navigation.account',
            pathname: '/account',
            classes: "ml-8"
        }
    ];

    let navItems = options.map((option, i) =>
        <NavigationItem icon={option.icon}
                        title={t(option.title)}
                        pathname={option.pathname}
                        iconRotation={option.rotation || 0}
                        classes={option.classes}
                        key={i}/>);

    /*
    if (options.length <= 3) {
        navItems = options.map((option, i) =>
            <NavigationItem icon={option.icon}
                            title={t(option.title)}
                            pathname={option.pathname}
                            iconRotation={option.rotation || 0}
                            classes={option.classes}
                            key={i}/>)
    } else {
        navItems = options.slice(0, 4).map((option, i) =>
            <NavigationItem icon={option.icon}
                            title={t(option.title)}
                            pathname={option.pathname}
                            iconRotation={option.rotation || 0}
                            classes={option.classes}
                            key={i}/>);
        navItems.push(<p
            className={"lg:m-2 lg:mt-3 cursor-pointer hover:text-orange-600 ml-8 " + (menuOpen ? "text-orange-600" : "text-gray-600")}
            onClick={() => setMenuOpen(!menuOpen)}
            key="navigation-menu"
        >
            <FontAwesomeIcon icon={['fad', 'bars']} className="text-2xl"/>
            <span className="text-xs block font-bold 600 mt-1">{t('navigation.more')}</span>
        </p>)
    }*/

    return <div>
        {/*<div className={"absolute w-full bg-red-500 transform z-20 " + (menuOpen ? "h-full": "h-0 hidden")}>List</div>*/}
        <div
            className="fixed bottom-0 left-0 bg-white w-full h-20 lg:w-20 lg:h-full rounded-t-custom lg:rounded-r-custom lg:rounded-t-none z-30">
            <div
                className="flex justify-center content-center flex-wrap h-full w-full text-center lg:content-start lg:mt-5">
                <NavigationItem icon={['fad', 'paw']} title="" pathname=""
                                classes="hidden lg:block lg:mb-8"/>
                {navItems}
            </div>
        </div>
    </div>
};

export default withRouter(Navigation);

