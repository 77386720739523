import React from 'react';
import {connect} from "react-redux"
import moment from "moment";
import {useTranslation} from "react-i18next";
import Card from "../utils/Card";
import SearchBar from "../utils/SearchBar";

const ListEvents = (props: any) => {

    const {t} = useTranslation();

    const {event} = props;

    const [filterData, setFilterData] = React.useState([]);

    React.useEffect(() => {
        if (event.isLoaded && !event.isEmpty) {
            setFilterData(event.events);
        }
    }, [event]);

    return <div>
        <SearchBar data={event.events} filter={setFilterData} field="title"/>
        {filterData.map((event: any) =>
            <Card title={moment(event.start_date).format("HH:mm DD.MM.YYYY")}
                  image={event.images.length > 0 && event.images[0].url}
                  text={event.title}
                  button={{target: "event/" + event.id, title: t("common.buy")}}
                  key={event.id}/>)}
    </div>;
};

const mapStateToProps = (state: any) => ({
    config: state.config,
    event: state.event,
});

export default connect(mapStateToProps)(ListEvents);
