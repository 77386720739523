import React, {useImperativeHandle} from "react";

const Modal = React.forwardRef((props: any, ref: any) => {

    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        }
    }));

    return <div
        className={(open ? "modal-active" : "opacity-0 pointer-events-none") + " modal fixed w-full h-full top-0 left-0 flex items-center justify-center z-50"}>
        <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-30"/>
        <div
            className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6" ref={ref}>
                <div className="flex justify-between items-center pb-3">
                    <p className="text-2xl font-bold">{props.title}</p>
                </div>
                {props.text}
                {props.component}
                <div className="flex justify-end pt-2">
                    {props.close &&
                    <button
                        className="modal-close bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2 "
                        onClick={() => setOpen(false)}>{props.close}
                    </button>
                    }
                    {props.action &&
                    <button
                        className="px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
                        onClick={() => setOpen(false)}>{props.action}
                    </button>}

                </div>
            </div>
        </div>
    </div>
});

export default Modal;
