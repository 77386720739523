import React from "react";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

const EventInfoPage = (props: any) => {

    const {t} = useTranslation();

    const [count, setCount] = React.useState(1);

    if (!props.event) return <p>Loading...</p>;
    return <div className="w-full rounded bg-white overflow-hidden">
        <div className="w-full bg-gray-400 h-64 flex flex-wrap justify-center content-center">
            <img src={props.event.images[0].url} alt=""/>
        </div>
        <div className="px-6 py-4">
            <div className="font-bold text-sm">{props.event.title}</div>
            <p className="text-gray-700 text-xs mb-2">Location: {props.event.location.title} </p>
            <div className="text-gray-700 text-sm" dangerouslySetInnerHTML={{__html: props.event.full_description}}>
            </div>
            <div className="flex flex-wrap my-4 h-10">
                <div className="flex flex-wrap items-stretch w-1/3 relative">
                    <div className="flex -mr-px">
                    <span
                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm"
                        onClick={() => count > 0 ? setCount(count - 1) : null}>-</span>
                    </div>
                    <input type="number"
                           className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-l-0 border-r-0 border border-grey-light px-1 relative text-center text-sm"
                           value={count}
                           onChange={(e) => setCount(parseInt(e.target.value) || 0)}
                    />
                    <div className="flex -mr-px">
                        <span
                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm"
                            onClick={() => setCount(count + 1)}
                        >+</span>
                    </div>
                </div>
                <div className="w-2/3 h-full flex flex-wrap content-center justify-end">
                    <p className="text-right text-xl font-bold">15€</p>
                </div>
            </div>
            <div className="w-full text-center mt-3">
                <button
                    className="bg-orange-400 hover:bg-orange-500 text-white text-sm font-bold py-1 px-4 rounded-full w-full lg:w-1/2">
                    {t("common.buy")}
                </button>
            </div>
        </div>
    </div>

};

const mapStateToProps = (state: any, props: any) => ({
    event: state.event.events.find((event: any) => event.id === props.match.params.id)
});

export default connect(mapStateToProps)(EventInfoPage);
