import axios from "axios";

/**
 * Loads events from WS-API
 * @param dispatch
 * @param getState
 * @returns {Promise<*>} dispatches events to {@link eventReducer}
 */
export const loadEvents = async (dispatch: any, getState: any) => {
    const id = getState().config.id;
    const result = await axios('https://ws-api.eventio.com/v1/' + id + '/events.json');
    return dispatch({type: 'ADD_EVENTS', events: result.data.events})
};
