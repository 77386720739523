import React from "react";
import faker from 'faker';
import Card from "../../utils/Card";

const SocialMediaCard = (props: any) => {

    const firstName = faker.name.firstName();
    const lastName = faker.name.lastName();

    return <Card icon={["fab", props.type]}
                 title={firstName + " " + lastName + " (@" + faker.internet.userName(firstName, lastName) + ")"}
                 text={faker.lorem.sentence(3) + " #" + faker.lorem.word()}
    />;
};

const SocialMedia = (props: any) => {
    return <div>
        <SocialMediaCard type="twitter"/>
    </div>
};

export default SocialMedia;
