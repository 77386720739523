import React, {useRef} from "react";
import Card from "../utils/Card";
import {ProductCard} from "../shop/ListProducts";
import {connect} from "react-redux";
import Modal from "../utils/Modal";
import {useTranslation} from "react-i18next";
import Select from "../utils/Select";

const Frontpage = (props: any) => {

    const ticket = useRef();
    const {t} = useTranslation();

    // @ts-ignore
    const open = () => ticket.current.handleOpen();

    const options = [
        {title: "Trip to Mexico", value: "mexico"},
        {title: "Trip to New York", value: "new-york"},
        {title: "Trip to Finland", value: "finland"}
    ];

    const RaffleTicketSelector = <Select title={t("demo.select-beneficiary")}
                                         options={options}
                                         onChange={(e: any) => console.log("Changed", e.target.value)}/>;

    return <div>
        <Modal component={RaffleTicketSelector} close={t("common.close")} action={t("common.buy")} ref={ticket}/>
        <Card title={t("demo.welcome-title")}
              text={t("demo.welcome-text")}/>

        <p className="mb-1 text-gray-600 text-sm font-bold">{t("account.tickets")}</p>
        <Card
            title="18.00 23.02.2020"
            text="Lawyer's Rock - Pink Pandas"
            button={{title: t("common.open"), target: "/event/5dc522a7333136c5398b457f/live"}}
        />

        <p className="mb-1 text-gray-600 text-sm font-bold">{t("demo.junior-support")}</p>
        <Card image="/uua.jpg"
              title={t("demo.junior-support-title")}
              text={t("demo.junior-support-text")}
              onClick={() => open()}
        />

        <p className="mb-1 text-gray-600 text-sm font-bold">{t("demo.products")}</p>
        <div className="flex flex-wrap content-between -mx-2">
            {props.product && props.product.products.slice(0, 2).map((product: any) => <ProductCard
                product={product} key={product.id}/>)}
        </div>

    </div>
};

const mapStateToProps = (state: any) => ({
    product: state.product
});

export default connect(mapStateToProps)(Frontpage);

