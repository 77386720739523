import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Product = (props: any) => <div>
    <div className="w-full rounded bg-white overflow-hidden">
        <div className="w-full bg-gray-400 h-64 flex flex-wrap justify-center content-center">
            <FontAwesomeIcon icon={['fad', 'mug']} className="text-5xl"/>
        </div>
        <div className="px-6 py-4">
            <div className="font-bold text-sm mb-2">Mug</div>
            <p className="text-gray-700 text-sm">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
            </p>
            <div className="flex flex-wrap my-4 h-10">
                <div className="flex flex-wrap items-stretch w-1/3 relative">
                    <div className="flex -mr-px">
                    <span
                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">-</span>
                    </div>
                    <input type="text"
                           className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border-l-0 border-r-0 border border-grey-light px-1 relative text-center text-sm"
                           value="1"/>
                    <div className="flex -mr-px">
                        <span
                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-l-none border border-l-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark text-sm">+</span>
                    </div>
                </div>
                <div className="w-2/3 h-full flex flex-wrap content-center justify-end">
                    <p className="text-right text-xl font-bold">15€</p>
                </div>
            </div>
        </div>
    </div>
</div>;

export default Product;
