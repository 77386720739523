import React from "react";
import {useTranslation} from "react-i18next";
import Select from "../utils/Select";
import Card from "../utils/Card";

const Account = (props: any) => {

    const {t, i18n} = useTranslation();

    const changeLanguage = (event: any) => i18n.changeLanguage(event.target.value);


    return <div>
        <div className="flex flex-wrap w-full h-32 content-center">
            <div className="w-2/3">
                <p className="text-xl text-gray-800 mt-0">Mikko Mallikas</p>
                <p className="text-sm text-gray-800">{t("account.balance")}: <span className="font-bold">200€</span></p>
            </div>
            <div className="w-1/3 rounded-full text-center flex flex-wrap justify-end">
                <img src="https://placekitten.com/g/64/64" className="rounded-full" alt=""/>
            </div>
        </div>

        <p className="mb-1 text-gray-600 text-sm font-bold">{t("account.tickets")}</p>
        <Card
            title="18.00 23.02.2020"
            text="Lawyer's Rock - Pink Pandas"
            button={{title: t("common.open"), target: "/event/5dc522a7333136c5398b457f/live"}}
        />

        <Card>
            <Select title={t("account.change-language")}
                    options={[{title: "Suomi", value: "fi"}, {title: "English", value: "en"}]}
                    onChange={changeLanguage}/>
        </Card>
        <Card>
            <button
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500">
                <span>{t("account.logout")}</span>
            </button>
        </Card>
    </div>

};

export default Account;
