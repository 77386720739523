import {combineReducers} from "redux";
import cartReducer from "./cartReducer";
import eventReducer from "./eventReducer";
import webserviceConfigReducer from "./webserviceConfigReducer";
import badgeReducer from "./badgeReducer";
import productReducer from "./productReducer";
export default combineReducers({
    cart: cartReducer,
    event: eventReducer,
    config: webserviceConfigReducer,
    badge: badgeReducer,
    product: productReducer,
});
