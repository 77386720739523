const initState = {
    isLoaded: false,
    isEmpty: false,
    errorMessage: null,
};

const badgeReducer = (state = initState, action: { type: string, badge: {}, err?: any }) => {
    switch (action.type) {
        case 'BADGE_CHECK_SUCCESS':
            return {
                ...action.badge,
                isLoaded: true,
                isEmpty: false,
                errorMessage: null
            };
        case 'BADGE_CHECK_ERROR':
            return {
                errorMessage: action.err.message,
                isLoaded: true,
                isEmpty: true
            };
        default:
            return state;
    }
};

export default badgeReducer;
