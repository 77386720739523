import React, {Suspense} from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import {connect} from "react-redux"
import {loadEvents} from "./store/actions/eventAction";
import ListEvents from "./components/events/ListEvents";
import ListProducts from "./components/shop/ListProducts";
import Product from "./components/shop/Product";
import Navigation from "./components/navigation/Navigation";
import Account from "./components/account/Account";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EventLivePage from "./components/events/EventLivePage";
import Frontpage from "./components/frontpage/Frontpage";
import EventInfoPage from "./components/events/EventInfoPage";

const App = (props) => {
    props.loadEvents();
    return <BrowserRouter>
        <Suspense fallback={<p>Loading...</p>}>
            <div className="w-full bg-gray-200 min-h-full lg:flex lg:flex-wrap lg:flex-row pb-20">
                <div className="w-full text-center pt-3 lg:hidden block">
                    {props.config && props.config.logo.type === "icon" &&
                    <FontAwesomeIcon icon={['fad', props.config.logo.url]} className="text-2xl"/>}
                </div>
                <div className="max-w-md md:max-w-2xl lg:w-9/12 px-5 p-5 pt-3 lg:ml-20">
                    <Route path="/" exact component={Frontpage}/>
                    <Route path="/events" exact component={ListEvents}/>
                    <Route path="/shop" exact component={ListProducts}/>
                    <Route path="/shop/product/:id?" exact component={Product}/>
                    <Route path="/event/:id" exact component={EventInfoPage}/>
                    <Route path="/event/:id/live" exact component={EventLivePage}/>
                    <Route path="/account" exact component={Account}/>
                </div>
                <Navigation/>
            </div>
        </Suspense>
    </BrowserRouter>;
};

const mapStateToProps = state => ({
    config: state.config
});

const mapDispatchToProps = dispatch => ({
    loadEvents: () => dispatch(loadEvents)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
