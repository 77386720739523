import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {connect} from "react-redux";
import SearchBar from "../utils/SearchBar";
import {useTranslation} from "react-i18next";

export const ProductCard = (props: any) => {

    const {t} = useTranslation();

    return <div className="w-1/2 px-2 mb-4">
        <div className="max-w-sm rounded bg-white overflow-hidden shadow-lg">
            <div className="w-full bg-gray-400 h-32 flex flex-wrap justify-center content-center">
                <FontAwesomeIcon icon={['fad', props.product.icon]} className="text-5xl"/>
            </div>
            <div className="px-6 py-4">
                <div className="font-bold text-sm mb-2">{props.product.name}</div>
                <p className="text-gray-700 text-sm">
                    {props.product.description}
                </p>
                <div className="w-full text-center mt-2">
                    <button
                        className="bg-orange-400 hover:bg-orange-500 text-white text-sm font-bold py-1 px-4 rounded-full w-full lg:w-1/2">
                        {t("common.buy")}
                    </button>
                </div>
            </div>
        </div>
    </div>;
}

const ListProducts = (props: any) => {

    const products = props.product && props.product.products;
    const [filterData, setFilterData] = React.useState(products);

    return <div>
        <SearchBar data={props.product.products} filter={setFilterData} field="name"/>
        <div className="flex flex-wrap content-between -mx-2">
            {filterData.map((product: any) => <ProductCard product={product} key={product.id}/>)}
        </div>
    </div>;
};

const mapStateToProps = (state: any) => ({
    product: state.product
});

export default connect(mapStateToProps)(ListProducts);
