import React from "react";
import {useTranslation} from "react-i18next";

const SearchBar = (props: { data: [], filter: any, field: string }) => {

    const {t} = useTranslation();

    const {data, filter, field} = props;

    const filterList = (e: any) => {
        const updatedList = data.filter((item: any) => item[field].toLowerCase().search(e.target.value.toLowerCase()) !== -1);
        filter(updatedList);
    };

    return <div className="relative w-full mb-5">
        <input
            className="appearance-none block w-full bg-gray-300 text-gray-700 rounded py-3 px-4 mb-3 leading-tight
                        focus:outline-none focus:bg-white transition-250"
            type="text" placeholder={t("common.search")}
            onChange={e => filterList(e)}
        />
    </div>
};

export default SearchBar;
