import React from "react";
import Card from "../../utils/Card";
import * as faker from "faker";

const LiveData = () => {
    return <Card title="Live feed">
        <ul>
            <li className="text-xs text-gray-700 font-semibold">23.00 - Match started!</li>
            <li className="text-xs font-semibold text-orange-700">23.15 - [Vote] Remember to vote the best player of this match!</li>
            <li className="text-xs text-gray-700 font-semibold">23.02 - {faker.name.firstName()} scores! Lawyer's Rock is taking the lead!</li>
            <li className="text-xs text-gray-700 font-semibold">23.07 - {faker.name.firstName()} scores! Lawyer's Rock is still leading!</li>
            <li className="text-xs font-semibold text-orange-700">23.15 - [Ad] Buy A Raffle Ticket tot support our juniors!</li>
            <li className="text-xs text-gray-700 font-semibold">23.27 - {faker.name.firstName()} scores! Lawyer's Rock has almost won the game.</li>
            <li className="text-xs text-green-700 font-semibold">23.30 - Lawyer's Rock won the game. (3-0)</li>
        </ul>
    </Card>
};

export default LiveData;
