const initState = {
    items: [],
    codes_used: [],
    totalEur: 0.00,
    errorMsg: null,
};

const cartReducer = (state = initState, action) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            // Get total euros
            let totalEur = state.totalEur;
            // Try to find the item from the cart
            let item = state.items.find(i => i.id === action.item.id);
            // If the item exists in the cart
            if (item) {
                // Remove old price from totalEur and apply correct amount of items and price
                totalEur -= item.price * item.amount;
                item.amount = action.item.amount;
                totalEur += item.price * item.amount;
            } else {
                // Apply item and price to the cart
                item = action.item;
                totalEur += item.price * item.amount;
            }

            // Remove the old item if needed
            const items = [...state.items, item].filter(i => {
                return i.id !== action.item.id
            });
            // Add the new item to the cart
            items.push(item);
            return {...state, items, totalEur, errorMsg: null};
        case 'REMOVE_FROM_CART':
            return {...state, items: state.items.filter(item => item.id !== action.item_id), errorMsg: null};
        case 'APPLY_COUPON_CODE':
            let price = 0.00;
            // Loop all items in cart
            const coupons = [...state.codes_used, action.coupon];
            /*let applied_items = [];
            state.items.map(item => {
                coupons.map(coupon => {
                    console.log("Trying to apply coupon", coupon.name, "for", item.title);
                    let useTimes = 0;
                    // Check if item does have any discounts
                    if (!coupons.find(c => c.target === item.id)) {
                        console.log("Not applying discount (item does not have any discount). Price:", price + item.amount * item.price);
                        return price += item.amount * item.price;
                    }
                    // Check if code does not apply for the item
                    if (coupon.target !== item.id) {
                        return console.log("Not applying discount (not a correct target). Price:", price + item.amount * item.price);
                    }
                    // Loop item amount
                    for (let i = 0; i < item.amount; i++) {
                        // If max usage is filled
                        if (useTimes === coupon.use_times) {
                            console.log("No more use times left");
                            price += item.price;
                            continue;
                        }
                        // Apply coupon value by type
                        // TODO: FIX PASKA KOODI
                        switch (coupon.type) {
                            case 'PRICE':
                                console.log("Applying a PRICE discount. Price:", price);
                                let discountItem = {...item, amount: 1};
                                discountItem.old_price = discountItem.price;
                                discountItem.price -= coupon.value;

                                price += (item.price - coupon.value);
                                item.amount -= 1;
                                console.log("Applied a PRICE discount. Price:", price);
                                return applied_items.push(discountItem);
                            case 'PERCENTAGE':
                                price += (item.price / coupon.value);
                                break;
                            default:
                                return null;
                        }
                        useTimes++;
                    }
                });
                applied_items.push(item);
            });*/
            return {
                ...state,
                totalEur: price,
                codes_used: coupons,
                errorMsg: 'Koodin syöttämisessä tapahtui virhe.',
            };
        case 'APPLY_COUPON_CODE_ERROR':
            return {...state, errorMsg: action.message};
        default:
            return state;
    }
};

export default cartReducer;