const initState = {
    products: [
        {
            id: 1,
            name: "Mug",
            description: "A Short Description",
            icon: "mug"
        },
        {
            id: 2,
            name: "T-Shirt",
            description: "A Short Description",
            icon: "tshirt"
        },
        {
            id: 3,
            name: "Scarf",
            description: "A Short Description",
            icon: "scarf"
        },
        {
            id: 4,
            name: "Serial Card",
            description: "A Short Description",
            icon: "credit-card-blank"
        }
    ]
};

const productReducer = (state = initState, action: { type: string, products: [] }) => {
    switch (action.type) {
        case 'ADD_PRODUCTS':
            return {
                products: action.products
            };
        default:
            return state;
    }
};

export default productReducer;
