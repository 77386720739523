import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {withRouter} from "react-router";

const NavigationItem = (props: any) => {

    return <p
        className={props.classes + " lg:m-2 lg:mt-3 cursor-pointer hover:text-orange-600 " + (props.history.location.pathname === props.pathname ? "text-orange-600" : "text-gray-600")}
        onClick={() => props.history.push(props.pathname)}>
        <FontAwesomeIcon icon={props.icon} transform={{rotate: props.iconRotation}} className="text-2xl"/>
        <span className="text-xs block font-bold 600 mt-1">{props.title}</span>
    </p>
};

export default withRouter(NavigationItem);
