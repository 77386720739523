const initState = {
    events: [],
    isLoaded: false,
    isEmpty: false,
};

const eventReducer = (state = initState, action: { type: string, events: [] }) => {
    switch (action.type) {
        case 'ADD_EVENTS':
            return {
                events: action.events,
                isLoaded: true,
                isEmpty: false,
            };
        default:
            return state;
    }
};

export default eventReducer;
