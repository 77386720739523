import React from "react";

const MatchStatus = (props: any) => <div
    className="bg-white shadow-xl rounded-lg overflow-hidden md:flex mb-5">
    <div className="flex flex-wrap w-full p-5">
        <div className="w-1/3 flex flex-wrap content-center justify-center">
            <div className="text-center">
                <p className="text-gray-700 text-sm font-bold pt-1">1</p>
                <p className="font-bold text-xs text-gray-600">Home</p>
            </div>
        </div>
        <div className="w-1/3 flex flex-wrap content-center justify-center">
            <div className="text-center">
                <p className="text-gray-700 text-sm font-bold pt-1">-</p>
                <p className="font-bold text-xs text-gray-600">Round 2</p>
            </div>
        </div>
        <div className="w-1/3 h-100 flex flex-wrap content-center justify-center">
            <div className="text-center">
                <p className="text-gray-700 text-sm font-bold pt-1">1</p>
                <p className="font-bold text-xs text-gray-600">Guest</p>
            </div>
        </div>
    </div>
</div>;

export default MatchStatus;
