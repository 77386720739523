import React, {useRef} from "react";
import MatchStatus from "./event-page-components/MatchStatus";
import SocialMedia from "./event-page-components/SocialMedia";
import Card from "../utils/Card";
import Modal from "../utils/Modal";
import {useTranslation} from "react-i18next";
import Select from "../utils/Select";
import * as faker from "faker";
import LiveData from "./event-page-components/LiveData";

const EventLivePage = (props: any) => {

    const ticket = useRef();
    const vote = useRef();

    const {t} = useTranslation();

    // @ts-ignore
    const open = () => ticket.current.handleOpen();
    // @ts-ignore
    const openVote = () => vote && vote.current.handleOpen();

    const options = [
        {title: "Trip to Mexico", value: "mexico"},
        {title: "Trip to New York", value: "new-york"},
        {title: "Trip to Finland", value: "finland"}
    ];

    const voteOptions = [
        {title: faker.name.firstName() + " " + faker.name.lastName(), value: faker.name.firstName().toLowerCase()},
        {title: faker.name.firstName() + " " + faker.name.lastName(), value: faker.name.firstName().toLowerCase()},
        {title: faker.name.firstName() + " " + faker.name.lastName(), value: faker.name.firstName().toLowerCase()},
    ];

    const RaffleTicketSelector = <Select title={t("demo.select-beneficiary")} options={options}
                                         onChange={(e: any) => console.log("Changed", e.target.value)}/>;
    const VoteSelector = <Select title={t("demo.vote-text")} options={voteOptions}
                                 onChange={(e: any) => console.log("Voted ", e.target.value)}/>;
    return <div>
        <Modal component={RaffleTicketSelector} close={t("common.close")} action={t("common.buy")} ref={ticket}/>
        <Modal component={VoteSelector} close={t("common.close")} action={t("common.vote")} ref={vote}/>
        <MatchStatus/>
        <LiveData/>
        <Card icon={["fad", "poll-people"]}
              title={t("demo.vote-title")}
              text={t("demo.vote-text")}
              onClick={() => openVote()}
        />
        <Card image="/uua.jpg"
              title={t("demo.junior-support-title")}
              text={t("demo.junior-support-text")}
              onClick={() => open()}
        />
        <p className="font-normal mb-1">Social media</p>
        <SocialMedia/>
    </div>
};

export default EventLivePage;
