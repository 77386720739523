import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faBarcode,
    faBars,
    faCalendar,
    faCalendarAlt,
    faChair,
    faComment,
    faHockeyPuck,
    faHotdog,
    faIdBadge,
    faMap,
    faMapMarked,
    faParking,
    faShoppingBag,
    faShoppingCart,
    faTicketAlt,
    faTrophy,
    faTshirt,
    faUser,
    faSearch,
    faMug,
    faScarf,
    faCreditCardBlank,
    faPaw,
    faPollPeople,
    faHome,
} from "@fortawesome/pro-duotone-svg-icons";

import {faCircle} from "@fortawesome/pro-regular-svg-icons";

import {faFacebook, faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import {applyMiddleware, createStore} from "redux";
import reducer from './store/reducers';
import {Provider} from "react-redux";
import thunk from "redux-thunk";
import './styles/tailwind.css';
import './i18n';
import axios from 'axios';
import {Helmet} from 'react-helmet';
library.add(faHome, faPollPeople, faTicketAlt, faHockeyPuck, faTshirt, faShoppingCart, faTrophy, faMap, faCalendar,
    faChair, faBarcode, faHotdog, faParking, faShoppingBag, faTwitter, faComment, faFacebook, faTimes, faCalendarAlt, faBars,
    faMapMarked, faIdBadge, faUser, faCircle, faSearch, faMug, faScarf, faCreditCardBlank, faPaw);

const store = createStore(reducer, applyMiddleware(thunk));

store.subscribe(() => console.log("Updated", store.getState()));

axios.get('https://ws-api.eventio.com/ws/config/' + window.location.hostname).then((res: any) => {
    const config = res.data.webservice;

    store.dispatch({
        type: 'APPLY_CONFIG',
        config
    });
    ReactDOM.render(<Provider store={store}>
        <Helmet>
            <title>{config.title.fi} - Elämys</title>
        </Helmet>
            <App/>
    </Provider>, document.getElementById('root'));
}).catch(() => ReactDOM.render(<p>Verkkokauppaa ei löydy</p>, document.getElementById('root')));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
